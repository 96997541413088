export const BUSINESS_URL = process.env.REACT_APP_BASE_URL;
// "https://bnpl.legalpay.in/";
// export const BUSINESS_URL = "https://bnpl.legalpay.in/";
// PRODUCTION //
// export const BUSINESS_URL = "https://maxapi.legalpay.in/";

// export const BUSINESS_URL = "http://localhost:8000/";

const BusinessApiEndPoint = {
  // aayush
  AUTH: "business/v1/api/user-authenticate/",
  OTP_VERIFICATION: "business/v1/api/otp-verify/",
  OTP_RESEND: "business/v1/api/resend-otp/",
  REGISTER_USER: "business/v1/api/user-signup/",
  GST_VALIDATE: "business/v1/validate/GST",
  GET_USER: "business/v1/api/user-details/",
  GET_NBFC_USER: "quick_settle/v1/nbfc-user-detail/",
  PASS_VERIFY: "business/v1/api/password-verify/",
  OTP_LOGIN: "business/v1/api/otp-login/",
  MERCHANT_OTP_LOGIN: "quick_settle/v1/auth/otp-login/",
  MERCHANT_PERSONAL_DETAILS: "quick_settle/v1/auth/user-signup/",
  NBFC_LOGIN: "quick_settle/v1/lending-partner/login/",
  CHANGE_PASS: "business/v1/api/change-password/",
  INVITE_LAWYER: "business/v1/api/lawyer-invite/",
  SEARCH: "/business/v1/api/search-lawyer/",
  ADD_LAWYER: "/business/v1/api/search-lawyer/add_lawyer/?user_id=",
  //  ayush kyc api
  BUSINESS_PAN: "business/v1/validate/PAN/",
  CIN: "business/v1/validate/cin/",
  COI: "business/v1/validate/certificate-upload/",
  DEED: "business/v1/validate/partnership-deed/",
  GSTIN: "business/v1/validate/GST",
  REFERAL: "business/v1/api/referal-notify/",
  KYC_PAN: "business/v1/validate/pan-upload/",
  KYC_AADHAR: "business/v1/validate/files/Aadhar/",
  KYC_BANK: "business/v1/validate/bank_account/",
  FINAL_KYC: "business/v1/api/user-details/",
  USER_CATEGORY: "business/v1/api/user-category/",
  // credit line
  TAKING_CONSENT:
    "/business/v1/credit-line/user-credit-line/verify_for_consent/",
  CREDIT_LINE_STEP: "/business/v1/credit-line/credit-status/",
  LLP_CIN: "/business/v1/credit-line/credit-status/incorporation-certificate/",
  BOARD_RESOLUTION: "/business/v1/credit-line/credit-status/board-resolution/",
  BANK_STATEMENT: "/business/v1/credit-line/credit-status/bank-statement/",
  FINANCIAL_STATEMENT:
    "/business/v1/credit-line/credit-status/financial-statement/",
  E_SIGN_CREDIT_LINE: "/business/v1/user-credit-line/Esign/",
  ESIGN_DOWNLOAD: "/business/v1/user-credit-line/CFAUpload/",

  // cm
  INVOICES: "business/v1/invoices/",
  REPORTS: "business/v1/invoices/report/",
  LAWYERS: "business/v1/lawyers/",
  PAYMENTS: "business/v1/payments/",
  STAT_REPORT: "business/v1/payments/report/",
  COMMENTS: "business/v1/invoice-comments/",
  ACTIVITY: "business/v1/activity-app/",
  GETOTP: "business/v1/get-otp/",
  CREATE_ORDER: "business/v1/pg-transaction/",
  CREDIT_LINE: "business/v1/user-credit-line/",
  APPLY_CREDIT: "business/v1/user-credit-line/apply_for_credit/",
  REQUEST_CREDIT: "business/v1/request-credit-line/",
  OTPFORCONSENT: "business/v1/user-credit-line/gen_otp_for_consent/",
  VERIFYOTPFORCONSENT: "business/v1/user-credit-line/verify_otp_for_consent/",
  BANK_ACCOUNT: "business/v1/bankAcc/",
  INVOICE_EMILIST: "business/v1/emi-apply/",
  EMI_LIST: "business/v1/emi-list/?invoiceEMIid=",
  EMI_TRANSACTION: "business/v1/emi-transaction/",
  CHECK_TRANSACTION_STATUS: "business/v1/pg-status/",

  // payment . . .
  DOC_SHARE: "lawfirm/v1/doc-share/",
  CREDIT_LINE_TX: "business/v1/credit-line-tx/",
  IDFY_DOC: "business/v1/credit-line/user-credit-line/document_upload/",

  // LF
  LF_CASE_DETAIL: "litigation/v1/case-details/",
  LF_ALL_CASES: "litigation/v1/all-cases/",
  LF_OVERVIEW: "litigation/v1/dashboard/parameter/",
  LF_ANALYTIC: "litigation/v1/dashboard/analytics/",
  LF_PICHART: "litigation/v1/pi-chart/",
  LF_RACTIVITY: "litigation/v1/dashboard/?action=recent-activity",
  LF_TOP_CASES: "litigation/v1/dashboard/",
  CREATE_CASE: "litigation/v1/create-nda/",
  UPLOAD_DOCUMENT: "litigation/v1/upload-document/",
  DELETE_DOCUMENT: "litigation/v1/upload-document/",
  GET_DOCUMENT: "litigation/v1/upload-document/",
  VERIFY_CIN: "litigation/v1/verify-cin",
  VERIFY_PAN: "litigation/v1/verify-pan",
  VERIFY_GSTIN: "litigation/v1/verify-gst",
  CREATE_CASE_RESPONDENT: "litigation/v1/create-respondent/",
  CREATE_CASE_DISPUTE_DROPDOWN: "litigation/v1/get-disputes/",
  DISPUTE_DETAILS: "litigation/v1/create-case/",
  GET_RESPONDENT: "litigation/v1/create-respondent/",
  GET_ADVOCATE: "litigation/v1/create-case/",
  GET_ALL_CLAIMANT: "litigation/v1/create-nda/",
  GET_EXISTING_CLAIMANT: "litigation/v1/nda-download/",
  NDA_SAVE: "litigation/v1/nda-download/",
  SUBMIT_CASE: "litigation/v1/upload-document/case-submit/",
  DELETE_ADVOCATE: "litigation/v1/create-case/delete/",
  DRAFT_CASES: "litigation/v1/nda-download/",
  LF_E_SIGN: "litigation/v1/e-sign-document/e-sign/",
  LF_E_SIGN_DOWNLOAD: "litigation/v1/e-sign-document/",

  //Quick Settle
  QS_VERIFY_PAN: "quick_settle/v1/kyc/pan/",
  QS_VERIFY_GSTIN: "quick_settle/v1/kyc/gst/",
  QS_VERIFY_CIN: "quick_settle/v1/kyc/cin/",
  QS_UPDATE_KYC_STATE: "quick_settle/v1/kyc/state-update/",
  QS_GET_KYC_STATE: "quick_settle/v1/kyc/verify-kyc/",
  QS_KYC_VERIFY: "quick_settle/v1/kyc/verify-kyc/",
  QS_VERIFY_AADHAR: "quick_settle/v1/kyc/aadhaar/",
  QS_UPLOAD_DOCUMENT: "quick_settle/v1/auth/upload-document/",
  QS_GST_CERTIFICATE: "quick_settle/v1/kyc/gst-certificate/",
  QS_ENTITY_TYPE: "quick_settle/v1/kyc/entity-category/",
  QS_CREATE_CASE: "quick_settle/v1/cases/create-case/",
  QS_OPPOSITE_PARTY: "quick_settle/v1/create-case/opposite-party/",
  QS_CASE_FINAL_STEP: "quick_settle/v1/cases/create-case/details/",
  QS_DRAFT_CASES: "quick_settle/v1/case/?is_draft=true",
  QS_ALL_CASES: "quick_settle/v1/case/",
  QS_AGREEMENT: "quick_settle/v1/cases/agreement-esign/",
  QS_DRAFT_CASES_DETAIL: "quick_settle/v1/draft-case",
  QS_DELETE_DRAFT_CASES: "quick_settle/v1/draft-case/",
  QS_RECENT_ACTIVITY: "quick_settle/v1/dashboard/activity/",
  QS_PAYMENT_REQUEST: "quick_settle/v1/cases/payment-request/",
  QS_DEBTOR_TRANSACTION: "quick_settle/v1/dashboard/debtor-transaction/",
  QS_DEBTOR_REPAYMENT: "quick_settle/v1/dashboard/debtor-repayments/",
  QS_MARK_READ: "quick_settle/v1/dashboard/activity/",
  QS_NBFC_CASES: "quick_settle/v1/cases/nbfc-cases/",
  QS_CASE_DETAIL: "quick_settle/v1/cases/details/",
  QS_EXTEND_CREDIT:
    "quick_settle/v1/dashboard/credit-details/increase_request/",
  QS_REPAYMENT: "quick_settle/v1/cases/details/repayments_details/",
  QS_INVOICES: "quick_settle/v1/get-invoice/",
  QS_ADD_INVOICE: "quick_settle/v1/cases/upload-invoice/",
  QS_BULK_UPLOAD: "quick_settle/v1/invoice-excel/upload/",
  QS_SAMPLE_EXCEL: "quick_settle/v1/upload-invoice/sample_excel/",
  QS_CREDIT_lINE: "quick_settle/v1/dashboard/credit-details/",
  QS_REFER_URL: "business/v1/api/referal-notify/",
  QS_UPDATE_USER: "quick_settle/v1/auth/user-update/",
  QS_MERCHANT_OTP_VERIFY: "quick_settle/v1/auth/otp-verify/",
  QS_ADD_BANK: "quick_settle/v1/dashboard/bank-account/",
  QS_GET_BANK_DATA: "quick_settle/v1/dashboard/bank-account/",
  QS_GET_MERCHANT_DASH: "quick_settle/v1/dashboard/payment-status/",
  QS_INVITE_CLIENTS: "quick_settle/v1/dashboard/invite-client/",
  QS_APPLICATION_STATUS: "quick_settle/v1/auth/credit-status/",
  QS_UPLOAD_EDIT_BULK_INVOICE: "quick_settle/v1/invoice-excel/add/",
  QS_BLOGS: "quick_settle/v1/blogs/",
  QS_SUBSCRIBE_NEWSLETTER: "quick_settle/v1/subscribe/",
  QS_CONTACT_US: "quick_settle/v1/contact-us/",
  QS_FAQ: "quick_settle/v1/faqs/",
  QS_MERCHANT_CHECK_CODE: "quick_settle/v1/auth/merchant-get/",
  NBFC_GET_CREDIT_LINE: "quick_settle/v1/lending-partner/credit-line/",

  // NBFC_CLIENT:"quick_settle/v1/lending-partner/client-list/",
  QS_GET_MERCHANT_DATA: "quick_settle/v1/case-parties/",
  QS_GET_CLIENT_PAYMENT_DETAILS: "quick_settle/v1/cases/client-tx-details/",

  QS_GET_DEBTOR_DATA: "quick_settle/v1/merchant-list/",
  QS_PAYMENT_DEBTOR: "quick_settle/v1/cases/request-transaction/",
  QS_PAYMENT_MERCHANT: "quick_settle/v1/cases/client-payment-req/",
  NBFC_CLIENT_LIST: "quick_settle/v1/lending-partner/client-list/?status=",
  NBFC_CREDIT_DETAILS: "quick_settle/v1/client-list/details/",
  NBFC_INCREASE_LIMIT: "quick_settle/v1/lending-partner/client-list/",
  RECAPTCHA: "quick_settle/v1/auth/verify-captcha/",
  GET_AADHAR_DIGIO: "quick_settle/v1/kyc/digi-locker/",
  DIGIO_DOWNLOAD_STEP_3: "quick_settle/v1/kyc/digi-download/?kid=",
  BLOG_DATA: "quick_settle/v1/blogs/",

  //T-PIN
  TPIN_SEND_OTP: "quick_settle/v1/cases/validate-tpin-otp/",
  TPIN_GENERATE: "quick_settle/v1/cases/generate-tpin/",
  VERIFY_TPIN: "quick_settle/v1/cases/check-tpin/",
  QS_CREDIT_AGREEMENT: "quick_settle/v1/auth/debtor-esign/",
  QS_NBFC_AGREEMENT: "quick_settle/v1/lending-partner/case-esign/",

  QS_PAYMENT_MARK_DONE:
    "quick_settle/v1/lending-partner/client-list/disbursement_details/",
  QS_ENACH_INITIATE_MANDATE: "quick_settle/v1/enach/initiate-mandate/",
  QS_AADHAR: "quick_settle/v1/kyc/aadhaar/",
  QS_CHEQUE_DETAILS: "quick_settle/v1/kyc/cheque-detail/",
  QS_NBFC_AGREEMENT_KFS: "quick_settle/v1/lending-partner/kfs-esign/",
  QS_NBFC_AGREEMENT_BFA: "quick_settle/v1/lending-partner/agreement-esign/",
  QS_PAYMENT_RQ_HISTORY: "quick_settle/v1/dashboard/case-list/",
  QS_PAYMENT_RQ_HISTORY_ESIGN: "quick_settle/v1/kyc/business-esign/",
  QS_DOC_DELETE: "quick_settle/v1/auth/upload-document/",
};

export default BusinessApiEndPoint;
